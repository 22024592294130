import React from "react"
import { FaLinkedinIn, FaApple, FaGooglePlay } from "react-icons/fa"
export default [
  {
    icon: <FaLinkedinIn />,
    url: "https://www.linkedin.com/company/pmqa/",
  },
  {
    icon: <FaApple />,
    url: "https://apps.apple.com/us/app/pmqa/id1463455599",
  },
  {
    icon: <FaGooglePlay />,
    url: "https://play.google.com/store/apps/details?id=com.app_pmqaapp.layout",
  },
]
