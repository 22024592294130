import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Facebook from './Facebook'
import Twitter from './Twitter' 

// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/
const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        siteUrl
        defaultTitle: title
        defaultDescription: description
        defaultBanner: banner
        headline
        siteLanguage
        ogLanguage
        author
        twitter
        facebook
      }
    }
  }
`

const SEO = ({ title, desc, banner, pathname, article, node }) => {
  const { site } = useStaticQuery(query)

  const {
    buildTime,
    siteMetadata: {
      siteUrl,
      defaultTitle,
      defaultDescription,
      defaultBanner,
      headline,
      siteLanguage,
      ogLanguage,
      author,
      twitter,
      facebook,
    },
  } = site

  const seo = {
    title: title || defaultTitle,
    description: desc || defaultDescription,
    //image: `${siteUrl}${banner || defaultBanner}`,
    image: `${banner || defaultBanner}`,
    url: `${siteUrl}/${pathname || ''}`,
  }
  
console.log(`**********************title:${seo.title} --desc:${seo.description} --seo.image:${seo.image} --url:${seo.url}`)
  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

  const schemaOrgWebPage = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: siteUrl,
    headline,
    inLanguage: siteLanguage,
    mainEntityOfPage: siteUrl,
    description: defaultDescription,
    name: defaultTitle,
    author: {
      '@type': 'Person',
      name: author,
    },
    copyrightHolder: {
      '@type': 'Person',
      name: author,
    },
    copyrightYear: '2019',
    creator: {
      '@type': 'Person',
      name: author,
    },
    publisher: {
      '@type': 'Person',
      name: author,
    },
    datePublished: '2019-01-18T10:30:00+01:00',
    dateModified: buildTime,
    image: {
      '@type': 'ImageObject',
      url: `${siteUrl}${defaultBanner}`,
    },
  }

  // Initial breadcrumb list

  const itemListElement = [
    {
      '@type': 'ListItem',
      item: {
        '@id': siteUrl,
        name: 'Homepage',
      },
      position: 1,
    },
  ]
  

  let schemaArticle = null

  if (article) {
    schemaArticle = {
      '@context': 'http://schema.org',
      '@type': 'Website',
      author: {
        '@type': 'Person',
        name: author,
      },
      copyrightHolder: {
        '@type': 'Person',
        name: author,
      },
      copyrightYear: '2019',
      creator: {
        '@type': 'Person',
        name: author,
      },
      publisher: {
        '@type': 'Organization',
        name: author,
        logo: {
          '@type': 'ImageObject',
          url: `${siteUrl}${defaultBanner}`,
        },
      },
      datePublished: node.first_publication_date,
      dateModified: node.last_publication_date,
      description: seo.description,
      headline: seo.title,
      inLanguage: siteLanguage,
      url: seo.url,
      name: seo.title,
      image: {
        '@type': 'ImageObject',
        url: seo.image,
      }, 
      mainEntityOfPage: seo.url,
    }
    // Push current blogpost into breadcrumb list
    itemListElement.push({
      '@type': 'ListItem',
      item: {
        '@id': seo.url,
        name: seo.title,
      },
      position: 2,
    })
  }

  
  

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'NGO',
    description: 'Breadcrumbs list',
    name: 'Breadcrumbs',
    address: {
      "@type": "PostalAddress",
      addressLocality: "Toronto, Canada",
      postalCode: "L4C 0A8",
      streetAddress: "9325 Yonge Street"
    },
    email: "info(at)pmqa.org",
    
    event: [
      {"@type": "Event",
      name:"Leadership workshop",
      description: "teaching leadership techniques",
      performer:"PMQA Voluteers",
      location: {
        "@type": "Place",
        "name": "RichmondHill Welcome Center",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "9325 Yonge Street",
          "addressLocality": "Richmond Hill",
          "postalCode": "L4C0A8",
          "addressRegion": "ON",
          "addressCountry": "CA"
          }
        },
      startDate: "2019",
      endDate: "2019",
      image: ""
      },


      {"@type": "Event",
      name:"Six Sigma Black Belt trainaning",
      description: "perparation for Six Sigma Black Belt Cerification",
      performer:"PMQA Voluteers",
      location: {
        "@type": "Place",
        "name": "RichmondHill Welcome Center",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "9325 Yonge Street",
          "addressLocality": "Richmond Hill",
          "postalCode": "L4C0A8",
          "addressRegion": "ON",
          "addressCountry": "CA"
          }
        },
      startDate: "2019",
      endDate: "2019",
      image: ""   },
      
      {"@type": "Event",
      name:"Six Sigma Black Belt trainaning",
      description: "perparation for Six Sigma Green Belt Cerification",
      performer:"PMQA Voluteers",
      location: {
        "@type": "Place",
        "name": "RichmondHill Welcome Center",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "9325 Yonge Street",
          "addressLocality": "Richmond Hill",
          "postalCode": "L4C0A8",
          "addressRegion": "ON",
          "addressCountry": "CA"
          }
        },
      startDate: "2019",
      endDate: "2019",
      image: "" },

      {"@type": "Event",
      name:"PMP",
      description: "PMP exam peperation",
      performer:"PMQA Voluteers",
      location: {
        "@type": "Place",
        "name": "RichmondHill Welcome Center",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "9325 Yonge Street",
          "addressLocality": "Richmond Hill",
          "postalCode": "L4C0A8",
          "addressRegion": "ON",
          "addressCountry": "CA"
          }
        },
      startDate: "2019",
      endDate: "2019",
      image: ""},

      {"@type": "Event",
      name:"ITIL",
      description: "ITIL introduction",
      performer:"PMQA Voluteers",
      location: {
        "@type": "Place",
        "name": "RichmondHill Welcome Center",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "9325 Yonge Street",
          "addressLocality": "Richmond Hill",
          "postalCode": "L4C0A8",
          "addressRegion": "ON",
          "addressCountry": "CA"
          }
        },
      startDate: "2019",
      endDate: "2019",
      image: ""},
      

        
      {"@type": "Event",
      name:"CCSIP",
      description: "Cyber Security workshop",
      performer:"PMQA Voluteers",
      location: {
        "@type": "Place",
        "name": "RichmondHill Welcome Center",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "9325 Yonge Street",
          "addressLocality": "Richmond Hill",
          "postalCode": "L4C0A8",
          "addressRegion": "ON",
          "addressCountry": "CA"
          }
        },
      startDate: "2019",
      endDate: "2019",
      image: ""}
      

    ],
    department: [
      {
        "@type":"Organization",
        name:"Supply Chain Management Group"
      },
      {
        "@type":"Organization",
        name:"Six Sigma Black Belt Group"
      },
      {
        "@type":"Organization",
        name:"IT Group"
      },
      {
        "@type":"Organization",
        name:"Leadership Group"
      }
    ],
    member: [
      {
        "@type": "Organization",
        name:"TRIEC (Toronto Region Immigrant Employment Council)"
      },
      {
        "@type": "Organization",
        name:"Federally registered non-for-profit organization"
      }
    ],
    name: "Persica Management & Quality Assurance association",
    }

  return (
    <>
      <Helmet title={seo.title}>
        <html lang={siteLanguage} />
        <meta name="google-site-verification" content="iIvjIjvbGJAWz3stdcfsDJ4f3TRwdqO3HzvEMyKHOCE" />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <meta name="PMQA Website" content="PMQA Website" />
        {/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
        {!article && <script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script>}
        {article && <script type="application/ld+json">{JSON.stringify(schemaArticle)}</script>}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Facebook
        desc={seo.description}
        image={seo.image}
        title={seo.title}
        type={article ? 'article' : 'website'}
        url={seo.url}
        locale={ogLanguage}
        name={facebook}
      />
      <Twitter title={seo.title} image={seo.image} desc={seo.description} username={twitter} />
    </>
  )
}
export default SEO
SEO.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  banner: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  node: PropTypes.object,
}
SEO.defaultProps = {
  title: null,
  desc: null,
  banner: null,
  pathname: null,
  article: false,
  node: null,
}
